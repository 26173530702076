import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getDistance } from 'geolib';
import Slider from 'react-slick';
import '@styles/slider.sass';

import { P } from '@styles/Typography';

import Link from '@components/Link';
import { MapIcon, ChevronLeftIcon, ChevronRightIcon } from '@components/Icons';

import * as S from './styles';

const settings = {
	adaptiveHeight: true,
	dots: true,
	infinite: false,
	speed: 500,
	draggable: true,
	prevArrow: (
		<S.Prev>
			<ChevronLeftIcon />
		</S.Prev>
	),
	nextArrow: (
		<S.Next>
			<ChevronRightIcon />
		</S.Next>
	),
};

const UnitCard = ({
	easiness,
	full_address,
	gmaps_link,
	images,
	lat,
	lng,
	name,
	myCoords,
	...rest
}) => {
	const [hasLocation, setHasLocation] = useState(false);
	const [distance, setDistance] = useState();

	useEffect(() => {
		if (Object.keys(myCoords).length) {
			const dist = getDistance(
				{ latitude: myCoords.lat, longitude: myCoords.lng },
				{ latitude: lat, longitude: lng }
			);

			setDistance(dist > 999 ? `${(dist / 1000).toFixed(1)}km` : `${dist}m`);

			setHasLocation(true);
		}
	}, [myCoords, lat, lng]);

	return (
		<S.Container {...rest}>
			<S.Images>
				<Slider {...settings}>
					{images.map(({ image }, index) => (
						<S.Image
							key={index}
							src={image.imgix_url}
							alt={`Fleury Unidade ${name} - ${full_address}`}
						/>
					))}
				</Slider>
			</S.Images>

			<S.Infos>
				{hasLocation && <S.Distance>{distance}</S.Distance>}
				<S.Name>
					<MapIcon />
					{name}
				</S.Name>
				<P>{full_address}</P>
				<S.Easiness>
					{easiness.map((easy, index) => (
						<S.Easy key={index} icon={easy.icon.imgix_url}>
							{easy.description}
						</S.Easy>
					))}
				</S.Easiness>

				<Link to={gmaps_link} label="Ver no mapa" external />
			</S.Infos>
		</S.Container>
	);
};

UnitCard.propTypes = {
	easiness: PropTypes.arrayOf(
		PropTypes.shape({
			description: PropTypes.string,
			icon: PropTypes.objectOf(PropTypes.string),
		})
	).isRequired,
	full_address: PropTypes.string.isRequired,
	gmaps_link: PropTypes.string.isRequired,
	images: PropTypes.arrayOf(
		PropTypes.shape({
			description: PropTypes.string,
			icon: PropTypes.objectOf(PropTypes.string),
		})
	).isRequired,
	lat: PropTypes.number.isRequired,
	lng: PropTypes.number.isRequired,
	myCoords: PropTypes.objectOf(PropTypes.number).isRequired,
	name: PropTypes.string.isRequired,
};

export default UnitCard;
