import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { H1, P } from '@styles/Typography';

import { Wrapper, Section } from '@components/Layout/styles';

import SEO from '@components/seo';
import Breadcrumb from '@components/Breadcrumb';
import CTA from '@components/CTAS/MobileServices';
import Sidebar from '@components/Sidebars/Main';
import UnitCard from '@components/Cards/UnitCard';

import * as S from './_styles';

const UnidadesPage = () => {
	const [myCoords, setMyCoords] = useState({});

	const { meta, pageData, units } = useStaticQuery(graphql`
		{
			meta: allCosmicjsMeta(filter: { slug: { eq: "meta-about" } }) {
				nodes {
					metadata {
						title
						description
						cover {
							imgix_url
						}
					}
				}
			}

			pageData: allCosmicjsPages(filter: { slug: { eq: "units-page" } }) {
				nodes {
					metadata {
						description
						title
					}
				}
			}

			units: allCosmicjsUnits {
				nodes {
					metadata {
						easiness {
							description
							icon {
								imgix_url
							}
						}
						full_address
						gmaps_link
						images {
							image {
								imgix_url
							}
						}
						lat
						lng
						name
					}
				}
			}
		}
	`);

	const {
		metadata: { title: pageTitle, description: pageDescription },
	} = pageData.nodes[0];

	const {
		metadata: { title: metaTitle, description: metaDescription, cover },
	} = meta.nodes[0];

	const unitList = units.nodes;

	useEffect(() => {
		const location = window.navigator && window.navigator.geolocation;

		if (location) {
			location.getCurrentPosition(position => {
				setMyCoords({
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				});
			});
		}
	}, []);

	return (
		<>
			<SEO
				title={metaTitle || 'Unidades'}
				description={metaDescription}
				cover={cover.imgix_url}
			/>

			<Wrapper>
				<Breadcrumb crumbs={[{ title: 'Unidades', slug: 'unidades' }]} />
			</Wrapper>

			<Section>
				<S.Wrapper>
					<S.MainContent>
						<H1>{pageTitle}</H1>
						<P>{pageDescription}</P>

						<S.UnitList>
							{unitList.map(
								(
									{
										metadata: {
											full_address,
											easiness,
											gmaps_link,
											images,
											lat,
											lng,
											name,
										},
									},
									index
								) => (
									<UnitCard
										key={index}
										full_address={full_address}
										easiness={easiness}
										gmaps_link={gmaps_link}
										images={images}
										lat={lat}
										lng={lng}
										name={name}
										myCoords={myCoords}
									/>
								)
							)}
						</S.UnitList>
					</S.MainContent>

					<Sidebar />
				</S.Wrapper>
			</Section>

			<Section bgColor="white">
				<Wrapper>
					<CTA />
				</Wrapper>
			</Section>
		</>
	);
};

export default UnidadesPage;
