import styled, { css } from 'styled-components';

import {
	boxShadow,
	colors,
	media,
	spacing,
	toRem,
	zIndex,
} from '@styles/Theme';
import { H5 } from '@styles/Typography';

const hideText = css`
	font-size: 0;
	text-indent: 100%;
`;

const slideNav = css`
	position: absolute;
	top: 0;
	z-index: ${zIndex.floated};
	display: flex !important;
	justify-content: center;
	width: ${spacing(6)};
	height: 100%;
	font-size: ${toRem(10)};

	svg {
		width: ${spacing(4)};
		color: white;
	}

	@media (${media.desktop}) {
		background-color: rgba(0, 0, 0, 0.3);
	}
`;

export const Container = styled.div`
	margin-bottom: ${spacing(2)};
	overflow: hidden;
	border-radius: ${spacing(2)};
	box-shadow: ${boxShadow};

	@media (${media.tablet}) {
		display: flex;
	}
`;

export const Images = styled.div`
	position: relative;

	.slick-dots {
		position: absolute;
		top: calc(100% - ${spacing(4)});
		display: flex !important;
		justify-content: center;
		width: 100%;

		li {
			button {
				${hideText}
				position: relative;
				display: block;
				width: ${spacing(2)};
				height: ${spacing(2)};
				background-color: transparent;
				border: none;
				outline: none;

				&::after {
					position: absolute;
					top: calc(50% - ${spacing(0.5)});
					left: calc(50% - ${spacing(0.5)});
					display: block;
					width: ${spacing(1)};
					height: ${spacing(1)};
					background-color: #fff;
					border-radius: 100%;
					transition: transform 0.2s linear;
					content: '';
				}
			}

			&.slick-active {
				button::after {
					transform: scale(1.5);
				}
			}
		}
	}

	@media (${media.tablet}) {
		width: ${toRem(358)};
		height: ${toRem(268)};
	}
`;

export const Image = styled.img`
	display: block;
	width: 100%;
`;

export const Prev = styled.div`
	${slideNav}
	left: 0;
`;

export const Next = styled.div`
	${slideNav}
	right: 0;
`;

export const Infos = styled.div`
	position: relative;
	padding: ${spacing(2, 3)};

	@media (${media.tablet}) {
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: center;
		padding: ${spacing(3, 4)};
	}
`;

export const Name = styled(H5)`
	display: flex;
	align-items: center;

	svg {
		width: ${spacing(2)};
		margin-right: ${spacing(1)};
		transform: translateY(${toRem(-2)});
	}

	@media (${media.tablet}) {
		svg {
			width: ${spacing(3)};
		}
	}
`;

export const Easiness = styled.ul`
	margin: ${spacing(2, 0)};
	padding-bottom: ${spacing(3)};
	border-bottom: ${toRem(1)} dashed ${colors.lightGrey};
`;

export const Easy = styled.li`
	position: relative;
	padding-left: ${spacing(3)};

	&:not(:last-child) {
		margin-bottom: ${spacing(1)};
	}

	&::before {
		position: absolute;
		top: calc(50% - ${spacing(1.2)});
		left: 0;
		width: ${spacing(2)};
		height: ${spacing(2)};
		background-image: url(${({ icon }) => icon});
		content: '';
	}
`;

export const Distance = styled.span`
	position: absolute;
	top: ${spacing(2)};
	right: ${spacing(2)};

	@media (${media.tablet}) {
		top: ${spacing(3)};
		right: ${spacing(3)};
	}

	@media (${media.desktop}) {
		top: ${spacing(4)};
		right: ${spacing(4)};
	}
`;
