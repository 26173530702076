import styled from 'styled-components';

import { media, spacing } from '@styles/Theme';

import { Wrapper as LWrapper } from '@components/Layout/styles';

export const MainContent = styled.div`
	@media (${media.desktop}) {
		flex: 1;
		margin-right: ${spacing(4)};
	}
`;

export const Wrapper = styled(LWrapper)`
	@media (${media.desktop}) {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
`;

export const UnitList = styled.div`
	margin-top: ${spacing(4)};
`;
